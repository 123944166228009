import React from "react";
import { graphql } from "gatsby";
import cn from "classnames";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import StarRating from "../components/star-rating";
import TestimonialAutor from "../components/testimonial-autor";
import { CodePreview } from "../components/docs/CodePreview";
import { CheckList, CheckListItem } from "../components/CheckList";

import awsLogo from "../images/aws-logo.svg";
import stripeIntegration from "../images/stripe-integration-graphic.svg";
import stripeIntegrationMobile from "../images/stripe-integration-graphic-mobile.svg";
import IconArrowRight from "../images/inlinesvgs/icons/arrow-right.svg";
import CheckMarkSolidIcon from "../images/inlinesvgs/checkmark-solid.svg";

import IconSubscribers from "../images/inlinesvgs/sidemail-hp-icons_subscriberlist.svg";
import IconPromotional from "../images/inlinesvgs/sidemail-hp-icons_promotional.svg";
import IconTransactional from "../images/inlinesvgs/sidemail-hp-icons_transactional.svg";
import IconAutomation from "../images/inlinesvgs/sidemail-hp-icons_automation.svg";

import ctaScreenshot from "../images/sidemail-public--homepage-cta.png";
import CtaCaptions from "../images/inlinesvgs/sidemail-public--homepage-cta-captions.svg";

import doggifyEmail from "../images/doggify-invoice.png";
import dolphinClubEmail from "../images/dolphinclub-promo.png";
import elephantyEmail from "../images/elephanty-welcome.png";
import protectIIDEmail from "../images/protectiid-sso.png";
import sidemailReportEmail from "../images/sidemail-report.png";
import sidemailTrialEmail from "../images/sidemail-trial.png";

import nickPhoto from "../images/testimonial-quicka-nick.jpg";
import julienPhoto from "../images/testimonial-tpop-julien.jpeg";
import hariPhoto from "../images/testimonial-visalist-hari.jpg";
import rikPhoto from "../images/testimonial-doka-rik.jpg";

const DISCOUNT_REFS = ["ph-l1", "ih-l1", "r-l1", "hn-l1", "bl-l1"];

async function makeConfetti() {
	const { default: confetti } = await import("canvas-confetti");

	const end = Date.now() + (window.innerWidth < 600 ? 1 : 3) * 1000;
	const colors = ["#0090f0", "#c5dbe8"];

	(function frame() {
		confetti({
			particleCount: 2,
			angle: 60,
			spread: 55,
			origin: { x: 0 },
			colors: colors,
			disableForReducedMotion: true,
		});
		confetti({
			particleCount: 2,
			angle: 120,
			spread: 55,
			origin: { x: 1 },
			colors: colors,
			disableForReducedMotion: true,
		});

		if (Date.now() < end) {
			requestAnimationFrame(frame);
		}
	})();
}

const IndexPage = ({ data, location }) => {
	const [selectedSolution, setSelectedSolution] = React.useState("api");
	const [selectedCodeExample, setSelectedCodeExample] = React.useState(
		"template"
	);
	const [discoutRef, setDiscountRef] = React.useState(null);

	React.useEffect(() => {
		if (!URLSearchParams) return;

		const params = new URLSearchParams(location.search);
		const discoutRefParam = params.get("discount-ref");

		if (DISCOUNT_REFS.includes(discoutRefParam)) {
			const domain =
				process.env.NODE_ENV === "production"
					? ".sidemail.io"
					: window.location.hostname;
			document.cookie = `discount-ref=${discoutRefParam};path=/;domain=${domain};max-age=259200`;
			setDiscountRef(discoutRefParam);
			makeConfetti();
		}
	}, [location.search]);

	const codeExamples = {
		template: `await sidemail.sendEmail({
	toAddress: "user@email.com",
	fromName: "Startup name",
	fromAddress: "your@startup.com",
	templateName: "Single sign-on",
	templateProps: { url: "https://your.app/sso?token=123" },
});`,
		scheduled: `await sidemail.sendEmail({
	toAddress: "user@email.com",
	fromName: "Startup name",
	fromAddress: "your@startup.com",
	templateName: "Welcome",
	templateProps: { firstName: "Patrik" },
	// Deliver email in 60 minutes from now
	scheduledAt: new Date(new Date().getTime() + 60 * 60000).toISOString()
});`,
		markdown: `
const markdown = \`
Hello world, {name}! 🖐

Lorem ipsum dolor sit amet, consectetur adipiscing elit.
	
[$btn Example button]({link})
	
Nam vulputate fringilla vestibulum. Nulla eu lobortis enim. Praesent varius, dui quis porta pretium, mi ex ultricies enim, sed volutpat purus erat vel nibh. 
\`.trim();
	
await sidemail.sendEmail({
	toAddress: "user@email.com",
	fromName: "Startup name",
	fromAddress: "your@startup.com",
	subject: "Testing markdown emails 😊",
	markdown,
	templateProps: {
		name: "John",
		link: "https://example.com",
	},
});
`.trim(),
		html: `await sidemail.sendEmail({
	toAddress: "user@email.com",
	fromName: "Startup name",
	fromAddress: "your@startup.com",
	subject: "Testing html only custom emails :)",
	html: "<html><body><h1>Hello world! 👋</h1><body></html>"
});`,
		plaintext: `await sidemail.sendEmail({
	toAddress: "user@email.com",
	fromName: "Startup name",
	fromAddress: "your@startup.com",
	subject: "Testing plain-text only custom emails :)",
	text: "Hello world! 👋"
});`,
	};

	return (
		<Layout
			pageMeta={{
				title: `Sidemail: Email platform for SaaS`,
				description: `Sidemail is an email platform for SaaS that makes sending emails simple. Deliver transactional emails via API, send marketing emails, set up automation, & more.`,
				discoutRef,
			}}>
			<Helmet>
				<link
					rel="preload"
					href="/assets/messenger.mp4"
					as="video"
					type="video/mp4"
				/>
				<link
					rel="preload"
					href="/assets/automation.mp4"
					as="video"
					type="video/mp4"
				/>
				<link
					rel="preload"
					href="/assets/contacts.mp4"
					as="video"
					type="video/mp4"
				/>
			</Helmet>

			<div id="homepage">
				<section className="aboveFold">
					<div className="container">
						<div className="itemCenter text-center mb-30">
							<div className="inline-block mr-5">
								<StarRating />
							</div>
							<div className="inline-block text-small text-uppercase">
								5-star rated customer support
							</div>
						</div>

						<h1 className="title-xxlarge text-dark text-center itemCenter mb-40">
							The new go-to email platform for your SaaS
						</h1>

						<p
							className="text-lead text-center itemCenter mb-50"
							style={{ maxWidth: 770 }}>
							<b className="text-dark">Here’s why:</b> easily create, integrate,
							send, automate, and analyze your emails. Sidemail comes packed
							with templates for any scenario and functionality to let you hit
							the ground running — in minutes. Meaning, you get to focus on what
							you do best, while your whole email game is as simple as making a
							cup of coffee.
						</p>

						<div className="itemCenter text-center">
							<a
								className="button button-primary button-large mr-5 ml-5 mb-15"
								href="https://client.sidemail.io/register">
								Start trial now
							</a>
							<a
								className="button button--bright button--outline button-large button--arrowAnimated mr-5 ml-5"
								href="/docs/email-sending-quickstart/">
								Visit API docs
								<IconArrowRight className="u-ml1" width={14} height={14} />
							</a>
						</div>
					</div>
				</section>

				<section
					id="solutions-desktop"
					className="dippedGradientBackground mb-150">
					<div className="container">
						<div className="box-whiteWithBorder">
							<div className="verticalList">
								<button
									className={cn("itemCenter selectionBox", {
										"is-selected": selectedSolution === "api",
									})}
									onClick={() => setSelectedSolution("api")}>
									<IconTransactional className="maxWidth-90 itemCenter icon-block mb-25" />
									<div className="text-center text-medium">
										Send transactional emails via API
									</div>
								</button>

								<button
									className={cn("itemCenter selectionBox", {
										"is-selected": selectedSolution === "newsletter",
									})}
									onClick={() => setSelectedSolution("newsletter")}>
									<IconPromotional className="maxWidth-90 itemCenter icon-block mb-25" />
									<div className="text-center text-medium">
										Send product updates & newsletters
									</div>
								</button>

								<button
									className={cn("itemCenter selectionBox", {
										"is-selected": selectedSolution === "subscribers",
									})}
									onClick={() => setSelectedSolution("subscribers")}>
									<IconSubscribers className="maxWidth-90 itemCenter icon-block mb-25" />
									<div className="text-center text-medium">
										All your subscribers in one place
									</div>
								</button>

								<button
									className={cn("itemCenter selectionBox", {
										"is-selected": selectedSolution === "automation",
									})}
									onClick={() => setSelectedSolution("automation")}>
									<IconAutomation className="maxWidth-90 itemCenter icon-block mb-25" />
									<div className="text-center text-medium">
										Automate emails & email sequences
									</div>
								</button>
							</div>
						</div>

						<section className="box-whiteWithBorder">
							{selectedSolution === "newsletter" && (
								<section className="solutionContainer">
									<div>
										<h2 className="title-large text-dark mb-30">
											Send product updates & newsletters
										</h2>
										<p className="text-large text-dark maxWidth-750 mb-20">
											Send product update emails (newsletters) and let your
											users know what’s new in your product with Messenger. It’s
											as easy as sending an email directly from your Gmail
											account, except you can send to all users and see the
											stats.
										</p>
										<a
											href="/targeted-email-messages"
											className="text-large link-featured mb-70">
											Learn more →
										</a>

										<div className="videoContainer">
											<video loop autoPlay playsInline muted height={300}>
												<source src="/assets/messenger.mp4" type="video/mp4" />
											</video>
										</div>
									</div>
								</section>
							)}

							{selectedSolution === "api" && (
								<section className="solutionContainer">
									<div>
										<h2 className="title-large mb-30">
											Send transactional emails via API
										</h2>
										<p className="text-large maxWidth-750 mb-20">
											Start sending emails like password reset or welcome email
											from your application in just a few minutes — Sidemail
											makes it incredibly easy to create, deliver, maintain, and
											analyze beautiful transactional emails.
										</p>
										<a
											href="/email-sending-api"
											className="text-large link-featured mb-70">
											Learn more →
										</a>

										<div className="mb-50">
											<button
												className={cn(
													"button-unstyled itemCenterVertical display-block mb-10",
													{
														"radioBtn--isActive":
															selectedCodeExample === "template",
													}
												)}
												onClick={() => setSelectedCodeExample("template")}>
												<span className="radioBtnPoint"></span>
												<span className="radioBtnLabel text-medium">
													Send no-code email template
												</span>
											</button>

											<button
												className={cn(
													"button-unstyled itemCenterVertical display-block mb-10",
													{
														"radioBtn--isActive":
															selectedCodeExample === "markdown",
													}
												)}
												onClick={() => setSelectedCodeExample("markdown")}>
												<span className="radioBtnPoint"></span>
												<span className="radioBtnLabel text-medium">
													Send transactional email with markdown
												</span>
											</button>

											<button
												className={cn(
													"button-unstyled itemCenterVertical display-block mb-10",
													{
														"radioBtn--isActive":
															selectedCodeExample === "scheduled",
													}
												)}
												onClick={() => setSelectedCodeExample("scheduled")}>
												<span className="radioBtnPoint"></span>
												<span className="radioBtnLabel text-medium">
													Schedule email delivery
												</span>
											</button>

											<button
												className={cn(
													"button-unstyled itemCenterVertical display-block mb-10",
													{
														"radioBtn--isActive":
															selectedCodeExample === "plaintext",
													}
												)}
												onClick={() => setSelectedCodeExample("plaintext")}>
												<span className="radioBtnPoint"></span>
												<span className="radioBtnLabel text-medium">
													Send custom plain text email
												</span>
											</button>

											<button
												className={cn(
													"button-unstyled itemCenterVertical display-block mb-10",
													{
														"radioBtn--isActive":
															selectedCodeExample === "html",
													}
												)}
												onClick={() => setSelectedCodeExample("html")}>
												<span className="radioBtnPoint"></span>
												<span className="radioBtnLabel text-medium">
													Send custom HTML email
												</span>
											</button>
										</div>

										<div>
											<CodePreview language="javascript">
												{codeExamples[selectedCodeExample]}
											</CodePreview>
										</div>
									</div>
								</section>
							)}

							{selectedSolution === "subscribers" && (
								<section className="solutionContainer">
									<div>
										<h2 className="title-large text-dark mb-30">
											All your subscribers in one place
										</h2>
										<p className="text-large maxWidth-750 mb-20">
											All your subscribers in one neatly organized place that
											always has enough room — we don’t charge per subscriber.
											Collect email addresses, and grow your email list with
											simple to set up subscribe forms that are easy to share.
										</p>
										<a
											href="/contact-profiles"
											className="text-large link-featured mb-70">
											Learn more →
										</a>
										<div className="videoContainer">
											<video loop autoPlay playsInline muted height={300}>
												<source src="/assets/contacts.mp4" type="video/mp4" />
											</video>
										</div>
									</div>
								</section>
							)}

							{selectedSolution === "automation" && (
								<section className="solutionContainer">
									<div>
										<h2 className="title-large mb-30">
											Automate emails and email sequences
										</h2>
										<p className="text-large maxWidth-750 mb-20">
											Set up email automation to deliver your email or sequence
											of emails when a defined event happens — for example, user
											sign-up. Use it to onboard new users, convert them into
											paying customers, and minimize churn.
										</p>
										<a
											href="/automated-email-sequences"
											className="text-large link-featured mb-70">
											Learn more →
										</a>
										<div className="videoContainer">
											<video loop autoPlay playsInline muted height={300}>
												<source src="/assets/automation.mp4" type="video/mp4" />
											</video>
										</div>
									</div>
								</section>
							)}
						</section>
					</div>
				</section>

				<section
					id="solutions-mobile"
					className="dippedGradientBackground mb-150">
					<div className="container">
						<div className="">
							<div className="box-whiteWithBorder mb-10">
								<a href="/email-sending-api" className="selectionBox">
									<IconTransactional />
									<div className="text-medium">
										Send transactional emails via API
									</div>
									<IconArrowRight />
								</a>
							</div>

							<div className="box-whiteWithBorder mb-10">
								<a href="/targeted-email-messages" className="selectionBox">
									<IconPromotional />
									<div className="text-medium">
										Send product updates & newsletters
									</div>
									<IconArrowRight />
								</a>
							</div>

							<div className="box-whiteWithBorder mb-10">
								<a href="/contact-profiles" className="selectionBox">
									<IconSubscribers />
									<div className="text-medium">
										All your subscribers in one place
									</div>
									<IconArrowRight />
								</a>
							</div>

							<div className="box-whiteWithBorder mb-10">
								<a href="/automated-email-sequences" className="selectionBox">
									<IconAutomation />
									<div className="text-medium">
										Automate emails and email sequences
									</div>
									<IconArrowRight />
								</a>
							</div>
						</div>
					</div>
				</section>

				<section className="container mb-150">
					<h2 className="maxWidth-750 text-center itemCenter mb-20 title-xlarge">
						<CheckMarkSolidIcon className="checkmarkIcon mr-20" />
						Make it yours in&nbsp;5&nbsp;clicks
					</h2>

					<p className="maxWidth-750 text-large text-dark text-center itemCenter mb-30">
						With Sidemail, you get a beautiful email design that you can fully
						customize — upload a logo, pick the right colors, and your favorite
						font (it makes such a huge difference!). Turn off the layout and
						send text-based emails to feel more personal.
					</p>

					<div className="itemCenter text-center text-light mb-70">
						<span className="mb-10 inline-block whitespace-nowrap">
							Mobile-responsive
						</span>
						<span className="inlineDotSeparator" />
						<span className="mb-10 inline-block whitespace-nowrap">
							Supports all email clients
						</span>
						<span className="inlineDotSeparator" />
						<span className="mb-10 inline-block whitespace-nowrap">
							White-label
						</span>
						<span className="inlineDotSeparator" />
						<span className="mb-10 inline-block whitespace-nowrap">
							Dark-mode
						</span>
						<span className="inlineDotSeparator" />
						<span className="mb-10 inline-block whitespace-nowrap">
							500+ Google fonts
						</span>
					</div>

					<div className="templatePreviewContainer">
						<div className="templatePreview">
							<img
								src={dolphinClubEmail}
								alt="An example of email with syntax-highlighted code snippet"
								loading="lazy"
							/>
						</div>

						<div className="templatePreview">
							<img
								src={doggifyEmail}
								alt="An example of receipt email"
								loading="lazy"
							/>
						</div>
						<div className="templatePreview">
							<img
								src={sidemailReportEmail}
								alt="An example of weekly statistics report with bar chart"
								loading="lazy"
							/>
						</div>

						<div className="templatePreview">
							<img
								src={elephantyEmail}
								alt="An example of welcome email with customized design"
								loading="lazy"
							/>
						</div>

						<div className="templatePreview">
							<img
								src={sidemailTrialEmail}
								alt="An example of text-based email"
								loading="lazy"
							/>
						</div>

						<div className="templatePreview">
							<img
								src={protectIIDEmail}
								alt="An example of email in dark mode"
								loading="lazy"
							/>
						</div>
					</div>
				</section>

				<section className="box-lightBackground box-fullWidthWrapper itemCenter mb-150">
					<div className="box-largeSize-120-100">
						<h2 className="maxWidth-680 text-center itemCenter mb-20 title-xlarge">
							<CheckMarkSolidIcon className="checkmarkIcon mr-20" />
							Stress‑free email&nbsp;delivery
						</h2>

						<p className="maxWidth-680 text-large text-dark text-center itemCenter mb-50">
							You’ll appreciate our robust email delivery that has been
							battle-tested by giants like Netflix, Stripe, Coinbase, HBO,
							Vodafone for almost a decade.
						</p>

						<div className="box-whiteWithBorder box-smallSize box--aws itemCenter itemCenterVertical maxWidth-880">
							<div>
								<div className="uptitle text-xsmall text-extraLight mb-20">
									Under the hood
								</div>
								<p className="text-medium maxWidth-480 mb-0">
									Sidemail is built upon Amazon’s AWS infrastructure and uses
									Amazon’s mail servers, which means{" "}
									<b className="text-dark">highly reliable</b> email delivery
									that <b className="text-dark">scales extremely well</b>.
								</p>
							</div>

							<img
								src={awsLogo}
								className="amazonLogo"
								loading="lazy"
								alt="AWS logo"
							/>
						</div>
					</div>
				</section>

				<section className="container mb-150">
					<div className="stripeSection">
						<div className="stripeSection-text maxWidth-550">
							<h2 className="title-large mb-20">Plug’n’play with Stripe</h2>

							<p className="text-large text-dark mb-30">
								Import your customers' emails from Stripe into Sidemail with one
								click, no code required. After that, you can automate sending of
								emails that help convert, engage, prevent churn & more.
							</p>

							<div className="text-dark">
								<CheckList>
									<CheckListItem>One click import</CheckListItem>
									<CheckListItem>Always up-to-date data</CheckListItem>
									<CheckListItem>The fastest way to get started</CheckListItem>
								</CheckList>
							</div>
						</div>

						<div className="stripeSection-graphic">
							<img
								src={stripeIntegration}
								loading="lazy"
								className="stripeSection-graphic-desktop"
								alt="Stripe integration graphic"
							/>
							<img
								src={stripeIntegrationMobile}
								loading="lazy"
								className="stripeSection-graphic-mobile"
								alt="Stripe integration graphic"
							/>
						</div>
					</div>
				</section>

				<div className="container mb-170">
					<hr className="separator" />
				</div>

				<div className="container mb-170">
					<div className="testimonialGrid">
						<section className="testimonialBoxSmall">
							<div className="mb-10">
								<StarRating />
							</div>

							<h3 className="testimonialTitle text-dark">
								Nothing could beat Sidemail
							</h3>

							<p className="text-medium line-height-large itemCenter">
								“We were absolutely blown away by how awesome the Sidemail
								offering has been for us at Quicka. We looked at rolling our own
								and leveraging existing providers but nothing could beat
								Sidemail for simplicity and ease of use for beautiful
								transactional emails. Thanks Patrik and the team!“
							</p>

							<TestimonialAutor
								name="Nick Glynn"
								position={
									<span className="text-light">
										Co-founder and CTO of{" "}
										<a href="https://www.quickapay.com/">QuickaPay</a>
									</span>
								}
								imgSrc={nickPhoto}
								imgAlt="Nick Glynn photo"
							/>
						</section>

						<section className="testimonialBoxSmall">
							<div className="mb-10">
								<StarRating />
							</div>

							<h3 className="testimonialTitle text-dark">
								More complete service than anything else on the market
							</h3>

							<p className="text-medium line-height-large itemCenter">
								“Great service, very easy to use but with many options. More
								complete service than anything else on the market, with a quick
								learning curve: a dream for our use. The Sidemail team is super
								nice and professional, always listening, it's great.“
							</p>

							<TestimonialAutor
								name="Julien Boulogne"
								position={
									<span className="text-light">
										Founder & CEO of <a href="https://www.tpop.com/en/">TPOP</a>
									</span>
								}
								imgSrc={julienPhoto}
								imgAlt="Julien Boulogne photo"
							/>
						</section>

						<section className="testimonialBoxSmall">
							<div className="mb-10">
								<StarRating />
							</div>
							<h3 className="testimonialTitle text-dark">
								Exactly what I needed
							</h3>
							<p className="text-medium line-height-large itemCenter">
								“My app messages sent with PHP mail() ended up in spam. I’m no
								email expert, and Sidemail seemed to do exactly what I needed,
								so I replaced mail() with a code snippet from Sidemail, and it
								just worked. I had some trouble setting up DNS, so I contacted
								the Sidemail support and quickly got a helpful response that
								resolved my issue.”
							</p>

							<TestimonialAutor
								name="Rik Schennink"
								position={
									<span className="text-light">
										Founder of <a href="https://pqina.nl/doka/">Pintura</a>
									</span>
								}
								imgSrc={rikPhoto}
								imgAlt="Rik Schennink photo"
							/>
						</section>

						<section className="testimonialBoxSmall">
							<div className="mb-10">
								<StarRating />
							</div>

							<h3 className="testimonialTitle text-dark">
								By far the easiest email service
							</h3>

							<p className="text-medium line-height-large itemCenter">
								“Sidemail is by far the easiest email service I have used. I was
								up and sending welcome emails in 10 mins. That’s it. It can send
								transactional, marketing, and newsletter emails. It supports
								dark mode from get-go and is ready for different email clients.
								All you have to do is upload two different logos for light and
								dark themes.“
							</p>

							<TestimonialAutor
								name="Hari Krishna Dalipudi"
								position={
									<span className="text-light">
										Founder of <a href="https://visalist.io/">Visalist</a>
									</span>
								}
								imgSrc={hariPhoto}
								imgAlt="Hari Krishna Dalipudi photo"
							/>
						</section>
					</div>
				</div>

				<section className="box-lightBackground box-fullWidthWrapper itemCenter">
					<div className="box-largeSize-120-100 text-center">
						<h2 className="title-xlarge text-dark mb-20">Sending in minutes</h2>

						<p className="text-lead itemCenter maxWidth-600 mb-30">
							Try Sidemail free for 7 days. No credit card required.
						</p>

						<a
							className="button button-primary button-large button--arrowAnimated mb-70"
							href="https://client.sidemail.io/register">
							Start trial
							<IconArrowRight className="u-ml1" width={18} height={18} />
						</a>

						<div className="position-relative">
							<div className="imgShadow-focused">
								<img
									src={ctaScreenshot}
									loading="lazy"
									alt="Sidemail dashboard screenshot"
								/>
							</div>
							<CtaCaptions
								className="imgCaptions"
								alt="Sidemail dashbord captions"
							/>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

export default IndexPage;

export const query = graphql`
	query {
		dashboardImage: file(
			relativePath: { eq: "sidemail-public--hp-organized.png" }
		) {
			childImageSharp {
				fluid(maxWidth: 1600) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
